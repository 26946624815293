import PropTypes from 'prop-types';
import Layout from '../components/layout';
import React, { useContext, useEffect, useState } from 'react';
import IconCheck from '../images/check.svg';
import styles from './confirm.module.css';
import {
  changeTransition,
  setRightsConsent,
} from '../webservices/ops-event-api';
import { navigate } from 'gatsby';
import { OpsEventContext } from '../ops-event.context';

let alreadyRun = false;
let stillDisplay = false;

function Confirm({ location }) {
  const {
    opsEvent,
    eventType,
    consents,
    initialConsents,
    updateConsents,
  } = useContext(OpsEventContext);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (
      !params.get('event-id') ||
      !params.get('candidate-id') ||
      !params.get('token')
    ) {
      navigate('/404');
      return;
    }

    if (!consents || alreadyRun) {
      return;
    }

    alreadyRun = true;
    changeTransition(
      params.get('candidate-id'),
      params.get('event-id'),
      'confirmed',
      params.get('token'),
      consents.rightsConsent
    )
      .then(() => setIsLoading(false))
      .catch(() => {
        navigate('/error');
      });
  }, [consents]);

  function check(path, value) {
    updateConsents(opsEvent, eventType, initialConsents, {
      ...consents,
      [path]: value,
    });

    const params = new URLSearchParams(location.search);

    setRightsConsent(
      params.get('candidate-id'),
      params.get('event-id'),
      'confirmed',
      params.get('token'),
      value
    );

    stillDisplay = true;
  }

  const needCV =
    eventType &&
    eventType.id !== 'e_job_dating' &&
    eventType.id !== 'buddy_pop' &&
    eventType.id !== 'pau_jeunes' &&
    eventType.id !== 'la_matinale_du_mentorat';

  const titleWording = (
    <h1 className={styles.confirmTitle}>
      Merci
      {eventType && eventType.id === 'e_job_dating' ? (
        <span> pour votre confirmation d&apos;inscription</span>
      ) : (
        <span> de vous être inscrit</span>
      )}
      &nbsp;!
    </h1>
  );

  const paragraphWording = (
    <p className={styles.confirmText}>
      Votre participation à l&apos;événement {eventType ? eventType.name : ''} a
      bien été enregistrée.{' '}
      {needCV && (
        <span>
          N&apos;oubliez pas d&apos;emmener un CV lors des entretiens&nbsp;!
        </span>
      )}{' '}
      À bientôt&nbsp;!
    </p>
  );
  return (
    <Layout location={location}>
      {!isLoading ? (
        <div>
          <div className={styles.confirmIcon}>
            <IconCheck width="3.3125em" height="3.3125em" />
          </div>
          {titleWording}
          {paragraphWording}
          {(eventType &&
            eventType.id === 'e_job_dating' &&
            initialConsents &&
            !initialConsents.rightsConsent) ||
            (stillDisplay && (
              <div className={styles.checkboxContainer}>
                <input
                  className={styles.checkbox}
                  type="checkbox"
                  id="rights-consent"
                  name="rights-consent"
                  defaultChecked={!!consents.rightsConsent}
                  onChange={() =>
                    check('rightsConsent', !consents.rightsConsent)
                  }
                />
                <label htmlFor="rights-consent">
                  J&apos;accepte que mes données soient transférées à la caisse
                  régionale du Crédit Agricole co-organisatrice du e-job dating
                  à des fins de sollicitations commerciales.
                </label>
              </div>
            ))}
        </div>
      ) : (
        <h1 className={styles.loadingTitle}>Chargement...</h1>
      )}
    </Layout>
  );
}

Confirm.propTypes = {
  location: PropTypes.object,
};

export default Confirm;
