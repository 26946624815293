import axios from 'axios';

import { env } from '../environment';

export function getOpsEvent(eventId) {
  return axios
    .get(`${env.api.opsEvent}/v2/event/${eventId}`)
    .then(({ data }) => {
      return {
        ...data,
        dateCreated: data.dateCreated
          ? new Date(data.dateCreated).toLocaleDateString('fr-FR', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })
          : null,
      };
    });
}

export function changeTransition(
  candidateId,
  eventId,
  transition,
  token,
  rightsConsent = false
) {
  if (window.WizbiiGdpr) {
    window.WizbiiGdpr.saveConsent({
      key: 'reglement',
      value: true,
    });
  }
  return axios.get(
    `${
      env.api.opsEvent
    }/api/candidate/${candidateId}/participation/${eventId}/transition/${transition}?token=${token}&rights-consent=${!!rightsConsent}`
  );
}

export function getConsents(candidateId, eventId, transition, token) {
  return axios
    .get(
      `${env.api.opsEvent}/api/candidate/${candidateId}/participation/${eventId}/consents-by-token/${token}`
    )
    .then(({ data }) => data);
}

export function setRightsConsent(
  candidateId,
  eventId,
  transition,
  token,
  rightsConsent = false
) {
  return axios
    .put(
      `${env.api.opsEvent}/api/candidate/${candidateId}/participation/${eventId}/consents-by-token/${token}`,
      { rightsConsent }
    )
    .then(({ data }) => data);
}

export function getEventTypes() {
  return axios.get(`${env.api.opsEvent}/v2/event-types`);
}
