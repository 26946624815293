import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';

import styles from './layout.module.css';
import LogoWizbii from '../images/wizbii.svg';
import { OpsEventContext } from '../ops-event.context';
import { env } from '../environment';
import { Helmet } from 'react-helmet';
import favicon16 from '../images/favicon-16x16.png';
import favicon32 from '../images/favicon-32x32.png';
import favicon96 from '../images/favicon-96x96.png';
import {
  getEventTypes,
  getOpsEvent,
  getConsents,
} from '../webservices/ops-event-api';
import { navigate } from 'gatsby';

function Layout({ children, location }) {
  const { opsEvent, setState } = useContext(OpsEventContext);

  useEffect(() => {
    if (opsEvent) {
      return;
    }

    const params = new URLSearchParams(location.search);

    Promise.all([
      getOpsEvent(params.get('event-id')),
      getEventTypes(),
      getConsents(
        params.get('candidate-id'),
        params.get('event-id'),
        'confirmed',
        params.get('token')
      ),
    ])
      .then(([event, eventTypes, consents]) => {
        const eventType = eventTypes.data.find(type => type.id === event.type);
        setState(event, eventType, consents);
      })
      .catch(err => {
        if (!err.response || err.response.status !== 404) {
          throw err;
        }

        navigate('/404');
      });
  }, []);

  return (
    <>
      <Helmet title="Wizbii Events">
        <html lang="fr" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800&display=swap"
        />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
        <link rel="icon" type="image/png" sizes="96x96" href={favicon96} />
      </Helmet>
      <div className={styles.layout}>
        <div className={styles.layoutLogo}>
          <LogoWizbii width="6em" />
        </div>

        <div className={styles.card}>
          <div className={styles.cardContent}>{children}</div>

          {opsEvent && [
            opsEvent.cvBookParameters && opsEvent.cvBookParameters.imageFileId && (
              <div className={styles.cardIllustration}>
                <img
                  className={styles.cardIllustrationImage}
                  src={`${env.googleStorage}/${env.wizbiiFiles}/${opsEvent.cvBookParameters.imageFileId}`}
                />
              </div>
            ),
          ]}
        </div>
      </div>
    </>
  );
}

Layout.propTypes = {
  location: PropTypes.object,
  children: PropTypes.node,
};

export default Layout;
